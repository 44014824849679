import { useEffect } from 'react';

// React Router Navigation
import Router from './routes';
import { useSearchParams } from 'react-router-dom';

// Component Imports
import ScrollToTop from './components/ScrollToTop';

// Custom MUI Theme
import ThemeConfig from './theme';
import CookieConsent from './components/CookieConsent';

export default function App(){
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const utmCampaign = searchParams.get('utm_campaign');
        const utmMedium = searchParams.get('utm_medium');
        const utmSource = searchParams.get('utm_source');
        const utmTerm = searchParams.get('utm_term');
        if (utmCampaign) {
            localStorage.setItem('utm_campaign', JSON.stringify(utmCampaign));
        }
        if (utmMedium) {
            localStorage.setItem('utm_medium', JSON.stringify(utmMedium));
        }
        if (utmSource) {
            localStorage.setItem('utm_source', JSON.stringify(utmSource));
        }
        if (utmTerm) {
            localStorage.setItem('utm_term', JSON.stringify(utmTerm));
        }
    }, [searchParams]);

    return (
        <ThemeConfig>
            <CookieConsent />
            <ScrollToTop />
            <Router />
        </ThemeConfig>
    );
};