import PropTypes from 'prop-types';

import * as React from 'react';

// Material UI
import { Dialog, styled, AppBar, Toolbar, IconButton, Link, Stack, Typography, Container  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/system';

// React Router Navigation
import { Link as RouterLink } from "react-router-dom";

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 58;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props}/>
});

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    position: 'static',
    backgroundColor: theme.palette.background.paper, 
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    
    [theme.breakpoints.up('md')]: {
        minHeight: APPBAR_DESKTOP,
    }
}));

MainSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function MainSidebar({ isOpenSidebar, onCloseSidebar }) {
    const LinkRouter = (props) => <Link underline='none' sx={{ textTransform: 'uppercase', color: "text.primary" }} component={RouterLink} {...props} />;
    const HrefLink = (props) => <Link underline='none' sx={{ textTransform: 'uppercase', color: "text.primary" }} {...props} />;

    return (
        <Dialog fullScreen open={isOpenSidebar} onClick={onCloseSidebar} onClose={onCloseSidebar} TransitionComponent={Transition}>
            <RootStyle>
                <Container maxWidth='lg'>
                    <ToolbarStyle>
                        <Typography style={{ lineHeight: 1 }} variant="h6" component={LinkRouter} to={`/`}>
                            Небо
                            <br/>
                            Сова
                        </Typography>

                        <Box sx={{ flexGrow: 1 }} />
                    
                        <IconButton onClick={onCloseSidebar} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </ToolbarStyle>
                </Container>
            </RootStyle>
            
            <Stack direction="column" alignItems="center" spacing={4} mt={10}>
                <Typography variant="h3" fontWeight={400} component={LinkRouter} to={`/`}>
                    Класс живописи
                </Typography>
                <Typography variant="h3" fontWeight={400} component={LinkRouter} to={`/membership`}>
                    Абонемент
                </Typography>
                <Typography variant="h3" fontWeight={400} component={HrefLink} href={`/schedule`}>
                    Расписание
                </Typography>
                <Typography variant="h3" fontWeight={400} component={LinkRouter} to={`/movement/list`}>
                    Направления
                </Typography>
                <Typography variant="h3" fontWeight={400} component={LinkRouter} to={`/contacts`}>
                    Контакты
                </Typography>
                <Typography variant="h3" fontWeight={400} component={Link} href='https://nebosova.tvoyklass.com/auth/login' target='_blank' sx={{ textDecoration: 'none', textTransform: 'uppercase', mx: 1.5 }}>
                    Вход
                </Typography>
            </Stack>
        </Dialog>
    );
}