// Material UI
import { Container, Typography } from "@mui/material";

// Project Imports
import Page from '../components/Page'

export default function Schedule() {

  return ( 
    <Page 
      title="НебоСова | Расписание"
      description="Расписание и онлайн запись на занятия."
      canonicalLink="/schedule"
    >
      <Container maxWidth='xl' sx={{pb: 5}}>
          <Typography component="h1" variant="h2" mb={4}>Расписание и онлайн запись</Typography>
          <div id="SiteWidgetMoyklass64726" style={{padding: 0}}></div>
        
      </Container>
    </Page>
  );
}