// ----------------------------------------------------------------------

function pxToRem(value) {
    return `${value / 16}rem`;
  }
  
  function responsiveFontSizes({ xs, sm, md, lg }) {
    return {
      '@media (min-width:0px)': {
        fontSize: pxToRem(xs)
      },
      '@media (min-width:600px)': {
        fontSize: pxToRem(sm)
      },
      '@media (min-width:900px)': {
        fontSize: pxToRem(md)
      },
      '@media (min-width:1200px)': {
        fontSize: pxToRem(lg)
      }
    };
  }
  
  // const FONT_PRIMARY = 'Public Sans, sans-serif';
  // const FONT_SECONDAY = 'Poppins, sans-serif';

  const FONT_PRIMARY = 'Acrom, sans-serif';
  const FONT_SECONDAY = 'Poppins, sans-serif';
  
  const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 700,
      lineHeight: 70 / 58,
      fontSize: pxToRem(58),
      ...responsiveFontSizes({ xs: 37, sm: 52, md: 58, lg: 58 })
    },
    h2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 700,
      lineHeight: 64 / 48,
      fontSize: pxToRem(48),
      ...responsiveFontSizes({ xs: 32, sm: 40, md: 44, lg: 48 })
    },
    h3: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(24),
      ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 })
    },
    h4: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(20),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
    },
    h5: {
      fontFamily: FONT_SECONDAY,
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
    },
    h6: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 400,
      lineHeight: 28 / 18,
      fontSize: pxToRem(16),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 16 })
    },
    subtitle1: {
      fontFamily: FONT_PRIMARY,
      lineHeight: 1.5,
      fontSize: pxToRem(16)
    },
    subtitle2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14)
    },
    body1: {
      fontFamily: FONT_SECONDAY,
      lineHeight: 1.5,
      fontSize: pxToRem(18)
    },
    body2: {
      fontFamily: FONT_SECONDAY,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14)
    },
    caption: {
      fontFamily: FONT_SECONDAY,
      lineHeight: 1.5,
      fontSize: pxToRem(12)
    },
    overline: {
      fontFamily: FONT_PRIMARY,
      display: 'block',
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      letterSpacing: 1.1,
      textTransform: 'uppercase'
    },
    button: {
      fontFamily: FONT_SECONDAY,
      fontWeight: 500,
      lineHeight: 24 / 14,
      fontSize: pxToRem(16),
      textTransform: 'capitalize'
    }
  };
  
  export default typography;