import PropTypes from 'prop-types';

// Material UI
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Link, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// React Router Navigation
import { Link as RouterLink } from "react-router-dom";

// Shared Components Import
import { MHidden } from '../../components/MHidden';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 58;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    borderBottom: '0.5px solid #DBDBDB',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    
    [theme.breakpoints.up('md')]: {
        minHeight: APPBAR_DESKTOP,
    }
}));

MainHeader.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function MainHeader({ onOpenSidebar }) {
    const LinkRouter = (props) => <Link underline='none' sx={{ textTransform: 'uppercase', mx: 1.5, color: "text.primary" }} component={RouterLink} {...props} />;

    const HrefLink = (props) => <Link underline='none' sx={{ textTransform: 'uppercase', mx: 1.5, color: "text.primary" }} {...props} />;

    return (
        <RootStyle>
            <Container maxWidth='xl'>
                <ToolbarStyle>
                    <Typography style={{ lineHeight: 1 }} variant="h6" component={LinkRouter} to={`/`}>
                        Небо
                        <br/>
                        Сова
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <MHidden width="mdDown">
                        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 3 }}>
                            <Typography variant="h6" component={LinkRouter} to={`/membership`}>
                                Абонемент
                            </Typography>
                            <Typography variant="h6" component={HrefLink} href={`/schedule`}>
                                Расписание
                            </Typography>
                            <Typography variant="h6" component={LinkRouter} to={`/movement/list`}>
                                Направления
                            </Typography>
                            <Typography variant="h6" component={LinkRouter} to={`/contacts`}>
                                Контакты
                            </Typography>
                            <Typography variant="h6" component={Link} href='https://nebosova.tvoyklass.com/auth/login' target='_blank' sx={{ textDecoration: 'none', textTransform: 'uppercase', mx: 1.5 }}>
                                Вход
                            </Typography>
                            
                        </Stack>
                    </MHidden>
                    <MHidden width="mdUp">
                        <IconButton onClick={onOpenSidebar} aria-label='menu'>
                            <MenuIcon  />
                        </IconButton>
                    </MHidden>
                </ToolbarStyle>
            </Container>
        </RootStyle>
    );
}