import { useState } from 'react';

// Material UI
import { styled } from '@mui/material/styles';

// React Routing
import { Outlet } from 'react-router-dom';

// Layout Imports
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import MainSidebar from './MainSidebar';

// MUI Styling
const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 100,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: 116,
  }
}));


export default function MainLayout() {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <MainHeader onOpenSidebar={() => setOpen(true)} />
      <MainSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <MainFooter />
    </RootStyle>
  );
}