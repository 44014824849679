import * as React from "react";
// React Routing
import { Navigate, useRoutes } from 'react-router-dom';

// Project Layout Imports
import MainLayout from "./layouts/Main";

// Component Imports
import Loader from './components/Loader'

// Project Page Imports
import Schedule from "./pages/Schedule.js";

const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));

const Contacts = React.lazy(() => import("./pages/Contacts"));
const ClassDetails = React.lazy(() => import("./pages/ClassDetails"));

const MovementListPage = React.lazy(() => import("./pages/MovementListPage"));
const MovementDetails = React.lazy(() => import("./pages/MovementDetails"));

const CareerList = React.lazy(() => import("./pages/CareerList"));
const CareerDetails = React.lazy(() => import("./pages/CareerDetails"));

const Membership = React.lazy(() => import("./pages/Membership"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

// const LabelCreate = React.lazy(() => import("./pages/LabelCreate"));
const LabelCreateUpdated = React.lazy(() => import("./pages/LabelCreateUpdated"));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element:  <React.Suspense fallback={<Loader />}><Home /></React.Suspense> },
        { path: 'about', element: <React.Suspense fallback={<Loader />}><About /></React.Suspense> },
        { path: 'contacts', element: <React.Suspense fallback={<Loader />}><Contacts /></React.Suspense> },
        { path: 'art-class', element: <React.Suspense fallback={<Loader />}><ClassDetails /></React.Suspense> },
        { path: 'schedule', element: <React.Suspense fallback={<Loader />}><Schedule /></React.Suspense> },

        {
          path: 'movement',
          children: [
            { element: <Navigate to="/movement/list" replace />, index: true },
            { path: 'list', element: <React.Suspense fallback={<Loader />}><MovementListPage /></React.Suspense> },
            { path: ':movementSlug', element: <React.Suspense fallback={<Loader />}><MovementDetails /></React.Suspense> },
          ],
        },

        {
          path: 'career',
          children: [
            { element: <Navigate to="/career/list" replace />, index: true },
            { path: 'list', element: <React.Suspense fallback={<Loader />}><CareerList /></React.Suspense> },
            { path: ':careerSlug', element: <React.Suspense fallback={<Loader />}><CareerDetails /></React.Suspense> },
          ],
        },

        { path: '/membership', element: <React.Suspense fallback={<Loader />}><Membership /></React.Suspense> }, 
        { path: '/privacy', element: <React.Suspense fallback={<Loader />}><Privacy /></React.Suspense> },
        { path: '/label', element: <React.Suspense fallback={<Loader />}><LabelCreateUpdated /></React.Suspense> },

      ]
   },

   { path: '/404', element: <React.Suspense fallback={<Loader />}><NotFound /></React.Suspense> },
   { path: '*', element: <Navigate to="/404" replace /> },
 ]);
}