import { Alert, Grow, Snackbar } from "@mui/material";

import { useEffect, useState } from "react";

export default function CookieConsent() {
    const cookieStorage = {
        getItem: (item) => {
            const cookies = document.cookie
                .split(';')
                .map(cookie => cookie.split('='))
                .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
            return cookies[item];
        },
        setItem: (item, value) => {
            document.cookie = `${item}=${value};`
        }
    }

    const storageType = cookieStorage;
    const consentPropertyName = 'jdc_consent';
    const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
    const saveToStorage = () => storageType.setItem(consentPropertyName, true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (shouldShowPopup(storageType)) {
            setTimeout(() => {
                setOpen(true);
            }, 2000);
        }
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') { return }

        saveToStorage(storageType);
        setOpen(false);
    };

    function GrowTransition(props) { return <Grow {...props} /> }

    return (
        <Snackbar
            open={open}
            autoHideDuration={null}
            TransitionComponent={GrowTransition}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity="success" color="info" sx={{ width: '100%' }}>
                Для корректного функционирования наш сайт, как и почти любой другой сайт в мире, использует файлы cookie.
            </Alert>
        </Snackbar>
    )
}