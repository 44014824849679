export default function Card(theme) {
    return {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: theme.shape.borderRadiusMd,
            position: 'relative',
            boxShadow: theme.customShadows.z12,
            zIndex: 0 // Fix Safari overflow: hidden with border radius
          }
        }
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: { variant: 'h6' },
          subheaderTypographyProps: { variant: 'body2' }
        },
        styleOverrides: {
          root: {
            padding: theme.spacing(3, 3, 0)
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: theme.spacing(3)
          }
        }
      }
    };
  }