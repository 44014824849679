import React from "react";

// Material UI
import { Box, Container, Grid, Link, Typography } from "@mui/material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        {`НебоСова`}
      </Link>{' '}
      2018–{new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function MainFooter() {
    const footers = [
        {
        title: 'Организация',
        content: [
            {
            name: 'Главная страница',
            link: '/'
            },
            {
            name: 'Абонемент',
            link: '/membership'
            },
            {
            name: 'Направления',
            link: '/movement/list'
            },
            {
            name: 'О нас',
            link: '/about'
            },
            {
            name: 'Контакты',
            link: '/contacts'
            },
            {
            name: 'Вакансии',
            link: '/career/list'
            }
        ]
        },
        {
        title: 'Соцсети',
        content: [
            {
            name: 'Telegram',
            link: 'https://t.me/mynebosova',
            target: '_blank'
            },
            {
            name: 'ВКонтакте',
            link: 'https://vk.com/neboandsova',
            target: '_blank'
            }
        ]
        },
        {
        title: 'Дополнительно',
        content: [
            {
            name: 'Конфиденциальность',
            link: '/privacy'
            },
            {
            name: 'Таблички для картин',
            link: '/label'
            },
            {
            name: 'Галерея НебоСова',
            link: 'https://gallery.nebosova.ru',
            target: '_blank'
            }
        ]
        }
    ];

    return (
        <Box sx={{ borderTop: '0.5px solid #DBDBDB', pt: 6, pb: 3 }}>
            <Container maxWidth='xl'>
                <Grid container spacing={4} justifyContent={{xs: "flex-start", sm: "space-between"}}>
                {footers.map((footer) => (
                    <Grid item xs={footer.title === 'Дополнительно' ? 12 : 6} sm={3} key={footer.title}>
                        <Typography component="h3" variant="h6" gutterBottom>
                            {footer.title}
                        </Typography>
                        <ul style={{listStyleType: 'none', padding: 0}}>
                        {footer.content.map((item) => (
                            <li key={item.name}>
                                <Link href={item.link} target={item.target || ''} variant="subtitle1" color="text.secondary">
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </Grid>
                ))}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </Box>
    );
}